// src/components/Articles.js
import React from 'react';
import { Link } from 'react-router-dom';

const Articles = () => {
    return (
        <div style={styles.container}>
            <h2>Articles</h2>
            <p>Welcome to my collection of articles! Here you will find insights on various topics that interest me.</p>
            <ul>
                <li><Link to="/bridging-the-gap">Bridging the Gap: Python Knowledge</Link></li>
                <li><Link to="/effective-troubleshooting">Mastering Effective Troubleshooting in Coding</Link></li>
                {/* Add more article links as needed */}
            </ul>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
    },
};

export default Articles;
