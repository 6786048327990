// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './components/About';
import Articles from './components/Articles';
import Contact from './components/Contact';
import BridgingTheGap from './components/BridgingTheGap';
import EffectiveTroubleshooting from './components/EffectiveTroubleshooting';

const App = () => {
    return (
        <Router>
            <div style={styles.container}>
                <nav style={styles.nav}>
                    <ul style={styles.navList}>
                        <li style={styles.navItem}><Link to="/">Home</Link></li>
                        <li style={styles.navItem}><Link to="/articles">Articles</Link></li>
                        <li style={styles.navItem}><Link to="/about">About Me</Link></li>
                        <li style={styles.navItem}><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
                
                <Routes>
                    <Route path="/" element={<Articles />} />
                    <Route path="/articles" element={<Articles />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/bridging-the-gap" element={<BridgingTheGap />} /> {/* New route for the article */}
                    <Route path="/effective-troubleshooting" element={<EffectiveTroubleshooting />} />
                </Routes>

                <footer style={styles.footer}>
                    <p>&copy; 2024 yukaju.com</p>
                </footer>
            </div>
        </Router>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'left',
    },
    nav: {
        marginBottom: '20px',
    },
    navList: {
        listStyleType: 'none',
        padding: 0,
        display: 'flex',
        gap: '15px',
    },
    navItem: {
        textDecoration: 'none',
        color: '#2980b9',
    },
    footer: {
        marginTop: '40px',
        textAlign: 'center',
        fontStyle: 'italic',
    },
};

export default App;
