// src/components/About.js
import React from 'react';

const About = () => {
    return (
        <div style={styles.container}>
            <h2>About Me</h2>
            <p>
                Hello! I’m a tech enthusiast and lifelong learner, navigating the fascinating intersection of technology and real-world applications.
            </p>
            <p>
                My journey began with a degree in Computer Science while serving as a National Guard Sergeant in Aviation. As a turbine engine mechanic, I learned the value of precision and teamwork under pressure, skills that have proven invaluable throughout my career.
            </p>
            <p>
                After completing my studies, I dove into the tech industry, working on a variety of projects across different sectors, including aviation, finance, and healthcare. Each experience has pushed my limits and helped me grow both personally and professionally.
            </p>
            <p>
                Currently, I serve as a Senior Engineer in the telecom industry, where I leverage my diverse background to drive innovation in connectivity solutions.
            </p>
            <p>
                Outside of work, I’m an avid cyclist, gamer, and reader. I love exploring the world of cryptocurrency and finance, always eager to learn and grow. I invite you to explore my articles, share your thoughts, or connect with me on social media.
            </p>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
    },
};

export default About;
