// src/components/Contact.js
import React from 'react';

const Contact = () => {
    return (
        <div style={styles.container}>
            <h2>Contact Me</h2>
            <p>If you would like to get in touch, please feel free to reach out!</p>
            {/* Contact form or details will go here */}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
    },
};

export default Contact;
